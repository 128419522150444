import React from 'react'
import {HeroHeaderImage,Header,Footer,LeftImageRightTextAboutTop,LeftImageRightTextAboutTop2,LeftImageRightTextAboutTop3,Map,CampanyData} from 'components';
const Company = () => {
  return (
    <>
      <Header/>
      <HeroHeaderImage>
       会社概要
      </HeroHeaderImage>
      <CampanyData/>
      
       <Footer/>
    </>
  )
}

export default Company
